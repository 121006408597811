// import {React, useEffect, useState} from "react";
// import Carousel from "./Carousel";
// import { marutiAmarkunj, sahjanandHeliconia, parulJani, himmatnagarBunglow, 
//     devangbhaiBinori, satvamgreens, labh
//  } from "../../images/imgFunc";



// const Completed = () => {
//     const [marutiIsOpen, setMarutiIsOpen] = useState(false);
//     const [sahjanandIsOpen, setSahjanandIsOpen] = useState(false);
//     const [parulIsOpen, setParulIsOpen] =  useState(false);
//     const [himmatnagarIsOpen, sethimmatnagarIsOpen] = useState(false);
    
//     const [marutiPhotoIndex, setMarutiPhotoIndex] = useState(0);
//     const [sahjanandPhotoIndex, setSahjanandPhotoIndex] = useState(0);
//     const [parulPhotoIndex, setParulPhotoIndex] = useState(0);
//     const [himmatnagarPhotoIndex, sethimmatnagarPhotoIndex] = useState(0);

//     useEffect(() => {
//         const w3csslink = document.createElement("link");
//         w3csslink.rel = "stylesheet";
//         w3csslink.type ="text/css";
//         w3csslink.href = "https://www.w3schools.com/w3css/4/w3.css";
//         document.head.appendChild(w3csslink);

//         return () => {
//             document.head.removeChild(w3csslink);
//         };
//     }, []);

//     // const [buttonPopup, setButtonPopup] = useState(false);
//     // const [popup, setPopup] = useState(false);


//     return (
//         <>
//         {/* {
//             popup && <PopupContent popup={popup} setPopup={setPopup}/>
//         } */}
//         <div style={{margin: '2rem 1rem'}}>
//             {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"/> */}
//             <div className="w3-row-padding completedProjects">
//                 <div className="w3-third w3-container projectsContainer">
//                     <Carousel slides={marutiAmarkunj} isOpen={marutiIsOpen} setIsOpen={setMarutiIsOpen} photoIndex={marutiPhotoIndex} setPhotoIndex={setMarutiPhotoIndex}></Carousel>
//                     <div className="carouselText">Maruti Amarkunj</div>
//                 </div>
               
//                 <div className="w3-third w3-container projectsContainer" >
//                     <Carousel slides={sahjanandHeliconia} isOpen={sahjanandIsOpen} setIsOpen={setSahjanandIsOpen} photoIndex={sahjanandPhotoIndex} setPhotoIndex={setSahjanandPhotoIndex}></Carousel>
//                     <div className="carouselText">Sahjanand Heliconia</div>
//                 </div>

//                 <div className="w3-third w3-container projectsContainer" >
//                     <Carousel slides = {parulJani} isOpen={parulIsOpen} setIsOpen={setParulIsOpen} photoIndex={parulPhotoIndex} setPhotoIndex={setParulPhotoIndex}></Carousel>
//                     <div className="carouselText">Saamarth H6, Koba</div>
//                 </div>

//                 <div className="w3-third w3-container projectsContainer" >
//                     <Carousel slides = {himmatnagarBunglow} isOpen={himmatnagarIsOpen} photoIndex={himmatnagarPhotoIndex} setIsOpen={sethimmatnagarIsOpen} setPhotoIndex={sethimmatnagarPhotoIndex}></Carousel>
//                     <div className="carouselText">Bunglow, Himmatnagar</div>
//                 </div>

//                 <div className="w3-third w3-container projectsContainer" >
//                     <Carousel slides = {devangbhaiBinori}></Carousel>
//                     <div className="carouselText">Binori Solitare, South Bopal</div>
//                 </div>


//                 <div className="w3-third w3-container projectsContainer" >

//                     <Carousel slides = {satvamgreens}></Carousel>
//                     <div className="carouselText"> Satvam Greens, Kudasan</div>
//                 </div>


//                 <div className="w3-third w3-container projectsContainer" >

//                     <Carousel slides = {labh}></Carousel>
//                     <div className="carouselText"> Labh Nirant, Kudasan</div>
//                 </div>
            
                
                
               
                
//             </div>
//         </div>  
//         </>
//     );
// };

// export default Completed;

import React, { useEffect, useState } from "react";
import Carousel from "./Carousel";
import { marutiAmarkunj, sahjanandHeliconia, parulJani, himmatnagarBunglow, devangbhaiBinori, satvamgreens, labh } from "../../images/imgFunc";

const Completed = () => {
    const [carouselStates, setCarouselStates] = useState({
        maruti: { isOpen: false, photoIndex: 0 },
        sahjanand: { isOpen: false, photoIndex: 0 },
        parul: { isOpen: false, photoIndex: 0 },
        himmatnagar: { isOpen: false, photoIndex: 0 },
        devangbhaiBinori: {isOpen: false, photoIndex: 0 }, 
        satvamgreens: {isOpen: false, photoIndex: 0}, 
        labh: {isOpen: false, photoIndex: 0}
    });

    useEffect(() => {
        const w3csslink = document.createElement("link");
        w3csslink.rel = "stylesheet";
        w3csslink.type = "text/css";
        w3csslink.href = "https://www.w3schools.com/w3css/4/w3.css";
        document.head.appendChild(w3csslink);

        return () => {
            document.head.removeChild(w3csslink);
        };
    }, []);

    const handleCarouselToggle = (carouselName) => {
        setCarouselStates(prevState => ({
            ...prevState,
            [carouselName]: {
                ...prevState[carouselName],
                isOpen: !prevState[carouselName].isOpen
            }
        }));
    };

    const handleSlideChange = (carouselName, newIndex) => {
        setCarouselStates(prevState => ({
            ...prevState,
            [carouselName]: {
                ...prevState[carouselName],
                photoIndex: newIndex
            }
        }));
    };

    return (
        <>
            <div style={{ margin: '2rem 1rem' }}>
                <div className="w3-row-padding completedProjects">
                    <div className="w3-third w3-container projectsContainer">
                        <Carousel
                            slides={marutiAmarkunj}
                            isOpen={carouselStates.maruti.isOpen}
                            setIsOpen={() => handleCarouselToggle("maruti")}
                            photoIndex={carouselStates.maruti.photoIndex}
                            setPhotoIndex={(newIndex) => handleSlideChange("maruti", newIndex)}
                        />
                        <div className="carouselText">Maruti Amarkunj</div>
                    </div>
                    <div className="w3-third w3-container projectsContainer">
                        <Carousel
                            slides={sahjanandHeliconia}
                            isOpen={carouselStates.sahjanand.isOpen}
                            setIsOpen={() => handleCarouselToggle("sahjanand")}
                            photoIndex={carouselStates.sahjanand.photoIndex}
                            setPhotoIndex={(newIndex) => handleSlideChange("sahjanand", newIndex)}
                        />
                        <div className="carouselText">Sahjanand Heliconia</div>
                    </div>
                    <div className="w3-third w3-container projectsContainer">
                    <Carousel
                            slides={parulJani}
                            isOpen={carouselStates.parul.isOpen}
                            setIsOpen={() => handleCarouselToggle("parul")}
                            photoIndex={carouselStates.parul.photoIndex}
                            setPhotoIndex={(newIndex) => handleSlideChange("parul", newIndex)}
                        />
                        <div className="carouselText">Saamarth H6, Koba</div>
                    </div>
                    <div className="w3-third w3-container projectsContainer">
                    <Carousel
                            slides={himmatnagarBunglow}
                            isOpen={carouselStates.himmatnagar.isOpen}
                            setIsOpen={() => handleCarouselToggle("himmatnagar")}
                            photoIndex={carouselStates.himmatnagar.photoIndex}
                            setPhotoIndex={(newIndex) => handleSlideChange("himmatnagar", newIndex)}
                        />
                        <div className="carouselText">Bunglow, Himmatnagar</div>
                    </div>
                    <div className="w3-third w3-container projectsContainer">
                    <Carousel
                            slides={devangbhaiBinori}
                            isOpen={carouselStates.devangbhaiBinori.isOpen}
                            setIsOpen={() => handleCarouselToggle("devangbhaiBinori")}
                            photoIndex={carouselStates.devangbhaiBinori.photoIndex}
                            setPhotoIndex={(newIndex) => handleSlideChange("devangbhaiBinori", newIndex)}
                        />
                        <div className="carouselText">Binori Solitare, South Bopal</div>
                    </div>
                    <div className="w3-third w3-container projectsContainer">
                    <Carousel
                            slides={satvamgreens}
                            isOpen={carouselStates.satvamgreens.isOpen}
                            setIsOpen={() => handleCarouselToggle("satvamgreens")}
                            photoIndex={carouselStates.satvamgreens.photoIndex}
                            setPhotoIndex={(newIndex) => handleSlideChange("satvamgreens", newIndex)}
                        />
                        <div className="carouselText">Satvam Greens, Kudasan</div>
                    </div>
                    <div className="w3-third w3-container projectsContainer">
                    <Carousel
                            slides={labh}
                            isOpen={carouselStates.labh.isOpen}
                            setIsOpen={() => handleCarouselToggle("labh")}
                            photoIndex={carouselStates.labh.photoIndex}
                            setPhotoIndex={(newIndex) => handleSlideChange("labh", newIndex)}
                        />
                        <div className="carouselText">Labh Nirant, Kudasan</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Completed;
