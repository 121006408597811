import React, {useState} from "react";
import { useMediaQuery } from '@react-hook/media-query';
import Logo from '../images/logo_1.png';
import { Navbar, Container, Nav, Overlay, Button } from 'react-bootstrap';
import {FaBars, FaTimes} from 'react-icons/fa';
import { SocialIcon } from 'react-social-icons';


function SiteNavbar() {
    const matches = useMediaQuery('only screen and (max-width: 992px)');
    const [showSidebar, setShowSidebar] = useState(false);

    const handleToggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    return (
        <div className='app-navbar'>
            {matches ? (
                /* Small Screen */
                <Navbar expand="lg" id="navbar-small" fixed="top">
                    <Container>
                        <Button variant="outline-dark" onClick={handleToggleSidebar}><FaBars /></Button>
                        <Navbar.Brand className="ml-auto logobrand"><a href="/"><img className="logoheader" src={Logo} alt="Logo" /></a></Navbar.Brand>
                        <div className="social-media">
                            <div className='social'>
                                <SocialIcon className="social-icons" url="https://www.youtube.com/@thatsmycraft9801"/>
                                <SocialIcon className="social-icons" url="https://www.linkedin.com/company/thatsmycraft/about/"/>
                                <SocialIcon className="social-icons" url="https://www.instagram.com/thatsmycraft/"/> 
                             </div>
                        </div>
                        <Overlay show={showSidebar} onHide={() => setShowSidebar(false)} placement="end">
                            <div className="sidebar">
                                <Button variant="outline-dark" onClick={handleToggleSidebar} className="close-button"><FaTimes /></Button>
                                <Nav className="ml-auto navbar-content">
                                    <Nav.Link href="/" className="links">Home</Nav.Link>
                                    <Nav.Link href="/aboutUs" className="links">About Us</Nav.Link>
                                    <Nav.Link href="/completed" className="links">Projects</Nav.Link>
                                    <Nav.Link href="/career" className="links">Career</Nav.Link>
                                </Nav>
                            </div>
                        </Overlay>
                    </Container>
                </Navbar>
            ) : (
                /* Big Screen */
                <Navbar expand="lg" id="navbar-big" className="navbar-big">
                    <Container style={{width: '100%'}}>
                        <Navbar.Brand><a href="/"><img className="logoheader" src={Logo} alt="Logo" /></a></Navbar.Brand>
                        <Nav className="ml-auto navbar-content-big">
                            <Nav.Link href="/" className="links2">Home</Nav.Link>
                            <Nav.Link href="/aboutUs" className="links2">About Us</Nav.Link>
                            <Nav.Link title="Project" href="/completed" className="links2">Projects</Nav.Link>
                            {/* <NavDropdown title="Project" id="basic-nav-dropdown" className="links2">
                                <NavDropdown.Item href="/completed">Completed</NavDropdown.Item>
                                <NavDropdown.Item href="/upcoming">Upcoming</NavDropdown.Item>
                            </NavDropdown> 
                            */}
                            {/* <Nav.Link href="/testimonial" className="links2">Testimonial</Nav.Link> */}
                            <Nav.Link href="/career" className="links2">Career @TMC</Nav.Link>
                            {/* <Nav.Link href="/contact" className="links2">Contact</Nav.Link> */}
                        </Nav>
                    </Container>
                </Navbar>
            )}
        </div>
    )
}

export default SiteNavbar;
