import React from 'react';

function Contact(){
    return(
        <div className="contact-us">
            Contact us @TMC <br/>

            email id
        </div>
    );
};

export default Contact;