import React, {useState, useEffect, useRef, useCallback} from "react";
// import Popup from "./Popup";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { marutiAmarkunj, sahjanandHeliconia } from '../../images/imgFunc';
// import About from '../home/about';

const Carousel = ({slides, isOpen, setIsOpen, photoIndex, setPhotoIndex}) => {
    // const [buttonPopup, setButtonPopup] = useState(false);
    // const [isOpen, setIsOpen] = useState(false);
    // const [photoIndex, setPhotoIndex] = useState(0);
    const images = marutiAmarkunj.map(item => item.url);

    const timerRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [transitionEnabled, setTransitionEnabled] = useState(false);


    const sliderContainerStyle = {
        cursor: 'pointer',
        width: `${slides.length * 100}%`,
        transform: `translateX(-${(currentIndex * 100) / slides.length}%)`,
    };

    const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = useCallback(() => {
        // setTransitionEnabled(true);
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }, [currentIndex, slides]);

    useEffect(() => {
        if(timerRef.current){
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            goToNext();
        }, 5500);
        
        return () => clearTimeout(timerRef.current);
    }, [goToNext]);

    const renderSlide = () => {
        return slides.map((slide, index) => {
            if (slide.type === "image") {
                return (
                    <div
                        key={index}
                        style={{ width: `${100 / slides.length}%` }}>
                    <img
                      src={slide.url}
                      alt={slide.title}
                      style={{ width: '100%', height: '100%' }}/>
                  </div>
                );
            }

            if (slide.type === "video") {
                return (
                    <div
                        key={index}
                        style={{ width: `${100 / slides.length}%` }}>
                        <video src={slide.url}
                        type="video/mp4"
                        autoPlay
                        loop
                        muted
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                    </div>
                );
            }
            return null;
        });
    };

    return(
        <div className='carouselContainer'>
            <div className='carousel-leftbtn' onClick={goToNext}> <FontAwesomeIcon icon={faCaretRight} size="xl" /> </div>
            <div className='carousel-rightbtn' onClick={goToPrev}> <FontAwesomeIcon icon={faCaretLeft} size="xl"/> </div>
            <div className="carouselSlider" style={sliderContainerStyle} onClick={() => setIsOpen(true)}>{renderSlide()}</div>
            {isOpen && (
                        <Lightbox
                            mainSrc={slides[photoIndex].url}
                            nextSrc={slides[(photoIndex + 1) % slides.length].url}
                            prevSrc={slides[(photoIndex + slides.length - 1) % slides.length].url}
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() => setPhotoIndex((photoIndex + slides.length - 1) % slides.length)}
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % slides.length)
                            }
                        />
                    )}
            {/* <Popup trigger={buttonPopup} setTrigger={setButtonPopup}><div className="popup-content"><About/></div></Popup> */}
        </div>
    )
};

export default Carousel;