export const marutiAmarkunj = [
    {url: require('./Maruti Amarkunj/1.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/2.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/3.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/4.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/5.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/6.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/7.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/8.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/9.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/10.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/11.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/12.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/13.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/14.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/15.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/16.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/17.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/18.jpg'), type: "image"},
    {url: require('./Maruti Amarkunj/19.jpg'), type: "image"},
];

export const sahjanandHeliconia= [
    {url: require('./Sahjanand Heliconia/1.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/2.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/3.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/4.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/5.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/6.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/7.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/8.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/9.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/10.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/11.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/12.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/13.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/14.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/15.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/16.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/17.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/18.jpg'), type: "image"},
    {url: require('./Sahjanand Heliconia/19.jpg'), type: "image"},
]

export const parulJani = [
    {url: require('./Parul Jani/1.jpg'), type: "image"},
    {url: require('./Parul Jani/2.jpg'), type: "image"},
    {url: require('./Parul Jani/3.jpg'), type: "image"},
    {url: require('./Parul Jani/4.jpg'), type: "image"},
    {url: require('./Parul Jani/5.jpg'), type: "image"},
    {url: require('./Parul Jani/6.jpg'), type: "image"},
    {url: require('./Parul Jani/7.jpg'), type: "image"},
    {url: require('./Parul Jani/8.jpg'), type: "image"},
    {url: require('./Parul Jani/9.jpg'), type: "image"},
    {url: require('./Parul Jani/10.jpg'), type: "image"},
    {url: require('./Parul Jani/11.jpg'), type: "image"},
    {url: require('./Parul Jani/12.jpg'), type: "image"},
    {url: require('./Parul Jani/13.jpg'), type: "image"},
    {url: require('./Parul Jani/14.jpg'), type: "image"},
    {url: require('./Parul Jani/15.jpg'), type: "image"},
    {url: require('./Parul Jani/16.jpg'), type: "image"},
    {url: require('./Parul Jani/17.jpg'), type: "image"},
    {url: require('./Parul Jani/18.jpg'), type: "image"},
    {url: require('./Parul Jani/19.jpg'), type: "image"},
    {url: require('./Parul Jani/DSC02020_1_2_Fusion-Interior.jpg'), type: "image"},
    {url: require('./Parul Jani/DSC02041_2_3_Fusion-Interior.jpg'), type: "image"},
    {url: require('./Parul Jani/DSC01942_3_4_Fusion-Interior.jpg'), type: "image"},
    {url: require('./Parul Jani/DSC01802_3_4_Fusion-Interior.jpg'), type: "image"},
    {url: require('./Parul Jani/DSC01817_8_9_Fusion-Interior.jpg'), type: "image"},
    {url: require('./Parul Jani/DSC01745_6_7_Fusion-Interior.jpg'), type: "image"},
]

export const devangbhaiBinori = [
    {url: require('./Devang bhai- Binori/1.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/2.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/3.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/4.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/5.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/6.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/7.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/8.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/9.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/AF400068_69_70_Balancer.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/AF400077_8_9_Balancer.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/AF409815_6_7_Fusion-Interior.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/AF409866_8_Fusion-Interior - Copy.jpg'), type: 'image'},
    {url: require('./Devang bhai- Binori/AF409995_6_7_Fusion-Interior.jpg'), type: 'image'},
]

export const himmatnagarBunglow = [
    {url: require('./Himmatnagar Bunglow/1.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/2.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/3.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/5.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/4.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/6.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/7.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/8.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/9.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/10.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/11.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/12.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/13.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/14.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/15.jpg'), type: 'image'},
    {url: require('./Himmatnagar Bunglow/16.jpeg'), type: 'image'},
]

export const satvamgreens = [
    {url: require('./Satvam Greens/1.jpg'), type: 'image'},
    {url: require('./Satvam Greens/2.jpg'), type: 'image'},
    {url: require('./Satvam Greens/3.jpg'), type: 'image'},
    {url: require('./Satvam Greens/4.jpg'), type: 'image'},
    {url: require('./Satvam Greens/5.jpg'), type: 'image'},
    {url: require('./Satvam Greens/6.jpg'), type: 'image'},
    {url: require('./Satvam Greens/7.jpg'), type: 'image'},
    {url: require('./Satvam Greens/8.jpg'), type: 'image'},
    {url: require('./Satvam Greens/9.jpg'), type: 'image'},
    {url: require('./Satvam Greens/10.jpg'), type: 'image'},
    {url: require('./Satvam Greens/11.jpg'), type: 'image'},
    {url: require('./Satvam Greens/12.jpg'), type: 'image'},
]

export const labh = [
    {url: require('./Labh 07/1.jpg'), type: 'image'},
    {url: require('./Labh 07/2.jpg'), type: 'image'},
    {url: require('./Labh 07/3.jpg'), type: 'image'},
    {url: require('./Labh 07/4.jpg'), type: 'image'},
    {url: require('./Labh 07/5.JPG'), type: 'image'},
    {url: require('./Labh 07/6.jpg'), type: 'image'},
    {url: require('./Labh 07/7.jpg'), type: 'image'},
    {url: require('./Labh 07/8.jpg'), type: 'image'},
    {url: require('./Labh 07/8.jpg'), type: 'image'},
    {url: require('./Labh 07/10.JPG'), type: 'image'},
    {url: require('./Labh 07/11.jpg'), type: 'image'},
    {url: require('./Labh 07/12.jpg'), type: 'image'},
]

