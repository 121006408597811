import React from "react";
import loadable from '@loadable/component';

const About = loadable(() => import("./about"), {
  fallback: 
    <div style={{height: '100%'}}>Loading...</div>
});

const ImgSliderlazy = React.lazy(() => import("../../components/img-slider"))

const Projects = loadable(() => import("./projects"), {
  fallback: <div style={{height: '50%'}}>Loading...</div>
});

function HomePage() {


  return (
    <div>
    <React.Suspense fallback={<div>Loading...</div>}>
      <ImgSliderlazy />
    </React.Suspense>
    <About/>
    <Projects/>
    </div>
  );
}

export default HomePage;
